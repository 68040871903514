<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 46 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Waving hand</title>
    <g clip-path="url(#clip0_2285_9366)">
      <path
        d="M16.9566 6.85997L6.51611 17.82C0.381349 24.26 0.381349 34.7 6.51611 41.16C12.6509 47.62 22.596 47.6 28.7498 41.16L43.8962 25.26L40.524 21.72L30.4264 32.32L29.0737 30.9L43.2104 16.06L39.8381 12.52L27.0352 25.96L25.6825 24.54L40.505 8.97997L37.1518 5.45997L22.3293 21.02L20.9957 19.6L33.1128 6.87997L29.7406 3.33997L13.5273 20.36C15.8516 23.5 15.642 28.04 12.8985 30.92L11.5458 29.5C13.7749 27.16 13.7749 23.34 11.5458 21.02L10.879 20.32L20.3098 10.42L16.9566 6.85997Z"
        :fill="color"
      />
      <path
        d="M16.9566 6.85997L6.51611 17.82C0.381349 24.26 0.381349 34.7 6.51611 41.16C12.6509 47.62 22.596 47.6 28.7498 41.16L43.8962 25.26L40.524 21.72L30.4264 32.32L29.0737 30.9L43.2104 16.06L39.8381 12.52L27.0352 25.96L25.6825 24.54L40.505 8.97997L37.1518 5.45997L22.3293 21.02L20.9957 19.6L33.1128 6.87997L29.7406 3.33997L13.5273 20.36C15.8516 23.5 15.642 28.04 12.8985 30.92L11.5458 29.5C13.7749 27.16 13.7749 23.34 11.5458 21.02L10.879 20.32L20.3098 10.42L16.9566 6.85997Z"
        fill="url(#paint0_linear_2285_9366)"
      />
      <path
        d="M13.3365 2C7.03027 2 1.90527 7.38 1.90527 14H4.76308C4.76308 9.04 8.61159 5 13.3365 5V2Z"
        fill="#E9EBED"
      />
      <path
        d="M32.3887 46C38.6949 46 43.8199 40.62 43.8199 34H40.9621C40.9621 38.96 37.1136 43 32.3887 43V46Z"
        fill="#E9EBED"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2285_9366"
        x1="290.944"
        y1="-169.229"
        x2="32.2806"
        y2="65.6087"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6E19F0" />
        <stop
          offset="1"
          stop-color="#6E19F0"
          stop-opacity="0"
        />
      </linearGradient>
      <clipPath id="clip0_2285_9366">
        <rect
          width="45.7249"
          height="48"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ic-hand',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    color: {
      type: String,
    },
  },
}
</script>
